import { Circle, DollarSign, Home, MessageSquare, Settings, Users } from "react-feather"
import { AiOutlineBars, AiOutlineHighlight, AiOutlineUser } from "react-icons/ai"
import { HiOutlineNewspaper } from "react-icons/hi"
import { HiOutlineSquaresPlus } from "react-icons/hi2"
import { DefaultNav } from "./DefualtNav"
import { LiaImagesSolid } from "react-icons/lia"
import { PiAirplaneTakeoff } from "react-icons/pi"
import { FaQuestion } from "react-icons/fa6"
import { MdTroubleshoot } from "react-icons/md"
export const WhatsappNavigation = [
    ...DefaultNav,
    {
        header: 'Whatsapp'
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: <Home size={20} />,
        navLink: '/merchant/whatsapp/'
    },
    {
        id: 'Contacts',
        title: 'Contacts',
        icon: <AiOutlineUser size={16} />,
        navLink: '/merchant/whatsapp/whatsapp_contact/'
    },
    {
        id: 'Groups',
        title: 'Groups',
        icon: <Users size={16} />,
        navLink: '/merchant/whatsapp/groups/'
    },
    {
        id: 'Templates',
        title: 'Templates',
        icon: <AiOutlineHighlight size={16} />,
        navLink: '/merchant/whatsapp/templates/'
    },
    {
        id: 'campaigns',
        title: 'Campaigns',
        icon: <AiOutlineBars size={16} />,
        navLink: '/merchant/whatsapp/campaigns'
    },
    {
        id: 'LiveChat',
        title: 'Chats',
        icon: <MessageSquare size={16} />,
        navLink: '/merchant/whatsapp/LiveChat/support'
    },
    // {
    //     id: 'Integration',
    //     title: 'Integration',
    //     icon: <HiOutlineSquaresPlus size={16} />,
    //     children: [
    //         {
    //             id: 'documentation',
    //             title: 'Documentation',
    //             icon: <Circle size={16} />,
    //             navLink: '/merchant/whatsapp/integration/'
    //         },
    //         {
    //             id: 'triggers',
    //             title: 'Triggers',
    //             icon: <Circle size={16} />,
    //             navLink: '/merchant/whatsapp/triggers/'
    //         },
    //         {
    //             id: 'Logs',
    //             title: 'Logs',
    //             icon: <HiOutlineNewspaper size={16} />,
    //             navLink: '/merchant/whatsapp/Logs/'
    //         }
    //     ]
    // },
    {
        id: 'Catalogue',
        title: 'Catalogue',
        icon: <LiaImagesSolid size={16} />,
        navLink: '/merchant/whatsapp/Catalogue/'
    },
    {
        id: 'widgets',
        title: 'widgets',
        icon: <Circle size={16} />,
        navLink: '/merchant/whatsapp/widgets/'
    },
    {
        id: 'Billing',
        title: 'Billing',
        icon: <DollarSign size={16} />,
        children: [
            {
                id: 'subscription',
                title: 'Subscriptions',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/Billing/Subscription'
            },
            {
                id: 'Wallet',
                title: 'Wallet & Credits',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/Billing/Wallet'
            },
            {
                id: 'payment',
                title: 'Payments',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/Billing/Payments'
            },
            {
                id: 'usage history',
                title: 'Usage History',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/Billing/Usage'
                // },
                // {
                //     id: 'billing',
                //     title: 'Billing Information',
                //     icon: <Circle size={16} />,
                //     navLink: '/merchant/whatsapp/billing5/'
            }
        ]

    },
    // {
    //     id: 'Billing',
    //     title: 'Billing',
    //     icon: <DollarSign size={16} />,
    //     navLink: '/merchant/whatsapp/Billing/'
    // },
    {
        id: 'onboarding',
        title: 'onboarding',
        icon: <PiAirplaneTakeoff size={16} />,
        navLink: '/merchant/whatsapp/onboarding/'
    },
    {
        id: 'setting',
        title: 'Settings',
        icon: <Settings size={16} />,
        children: [
            {
                id: 'profile',
                title: 'Profile',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/project-profile/'
            },
            {
                id: 'optin',
                title: 'Manage Opt-in',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/optinManage/'
            },
            {
                id: 'quickReply',
                title: 'Quick Replies',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/quick-reply/'
            },
            {
                id: 'AdminNotificationTable',
                title: 'Administrator',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/admin-notification/'
            },
            {
                id: 'integration',
                title: 'Integrations',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/integration/'
            },
            {
                id: 'Account',
                title: 'Accounts',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/is_business/'
            },
            {
                id: 'triggers',
                title: 'Triggers',
                icon: <Circle size={16} />,
                navLink: '/merchant/whatsapp/triggers/'
            }
        ]
    },
    {
        id: 'faq',
        title: 'Faq',
        icon: <FaQuestion size={16} />,
        navLink: '/merchant/whatsapp/faq/'
    },
    {
        id: 'troubleShoot',
        title: 'Troubleshoot',
        icon: <MdTroubleshoot size={16} />,
        navLink: '/merchant/whatsapp/troubleshoot/'
    }
]