import { Circle, Home } from "react-feather"
import { DefaultNav } from "./DefualtNav"

export const OhMyCustomerNavigation = [
    ...DefaultNav,
    {
        header: 'Oh My Customer'
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: <Circle size={20} />,
        navLink: '/merchant/oh-my-customer/'
    },
    {
        id: 'customization',
        title: 'Customization',
        icon: <Circle size={20} />,
        navLink: '/merchant/oh-my-customer/customization/'
    },
    {
        id: 'themes',
        title: 'Themes',
        icon: <Circle size={20} />,
        navLink: '/merchant/oh-my-customer/theme_library/'
    },
    {
        id: 'instructions',
        title: 'instructions',
        icon: <Circle size={20} />,
        navLink: '/merchant/oh-my-customer/instructions/'
    },
    {
        id: 'settings',
        title: 'Settings',
        icon: <Circle size={20} />,
        navLink: '/merchant/oh-my-customer/settings/'
    }
    // {
    //     id: 'user',
    //     title: 'User',
    //     icon: <Circle size={20} />,
    //     navLink: '/merchant/oh-my-customer/user/'
    // }
]